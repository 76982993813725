import React from "react"
import {   ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  ImageWithZoom,
  Slide,
  Slider } from 'pure-react-carousel';
import vip from "../../images/market-place/vip.svg"
import mint from "../../images/market-place/mint.svg"
import volgai from "../../images/market-place/volgai_logo.jpg"
import volkslift from "../../images/market-place/volkslift.png"
import cornerston from "../../images/market-place/cornerston.svg"
import anconsult from "../../images/market-place/anconsult.png"
import cocalex from "../../images/market-place/cocalex.png"
import sweetNote from "../../images/market-place/sweet-note.svg"
import checkboxImg from "../../images/new-pricing/checked.svg"
import inpLogo from "../../images/market-place/inp-logo.png"
import collabartsLogo from "../../images/market-place/collabarts-logo.png"
import pisellLogo from "../../images/market-place/pisell-logo.png"
import ziiLogo from "../../images/market-place/zii_logo.png"
import cfoLogo from "../../images/market-place/cfo-logo.png"
import targetAccoutingLogo from "../../images/market-place/target-accounting-logo.png"
import ozdLogo from "../../images/market-place/ozd_accounting_logo.jpg"
import leftArrow from "../../images/stratergic-business-partner/left-direction.svg"
import rightArrow from "../../images/stratergic-business-partner/right-direction.svg"
import financeMadeSimpleLogo from "../../images/market-place/finance_made_simple_logo.jpg"


const market_places = [
  {
    header_img: vip,
    heading1: "Cleaning Business",
    heading2: "MY VIP Cleaning",
    link: "https://myvipcleaningservice.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "We provide professional cleaning services for homes, offices, Industries & Events, etc. We have a team of leading trained cleaning professionals in Melbourne who work with full care and devotion to providing a neat"
  },
  {
    header_img: volgai,
    imgClassName: "w-145px flex items-center",
    heading1: "Software Development",
    heading2: "VolgAI",
    link: "https://volgai.com.au",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "VolgAI is a managed IT services company supporting organizations with complex requirements to find solutions that span their entire businesses. We are based in Melbourne but our services aren’t confined to one single country."
  },
  {
    header_img: volkslift,
    heading1: "Building & Construction",
    imgClassName: "w-145px min-h-100px flex items-center",
    heading2: "Volkslift Australia",
    link: "https://volkslift.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "Volkslift,Germany, established since 1935, is based in Cologne, Germany. Since the production of it’ s first passenger elevator, Volkslift remained here as the pioneer and market leader in the elevator industry of Europe."
  },
  {
    header_img: sweetNote,
    heading1: "Design",
    heading2: "Sweet Notes Studio",
    link: "https://sweetnotesstudio.com/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "We provide professional cleaning services for homes, offices, Industries & Events, etc. We have a team of leading trained cleaning professionals in Melbourne who work with full care and devotion to providing a neat"
  },
  {
    header_img: inpLogo,
    heading1: "Accounting & Business Consulting",
    heading2: "INP Group",
    checkboxImg: checkboxImg,
    imgClassName: "w-133px",
    link: "https://www.inpgroup.com.au",
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "INP Group is a well-established chartered accounting firm with extensive experience across various sectors and different sizes of clients including listed companies and large private international businesses."
  },
  {
    header_img: collabartsLogo,
    heading1: "Design",
    heading2: "Collabarts",
    checkboxImg: checkboxImg,
    link: "https://www.collabarts.com.au",
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "Never worry about content anymore. Talk us through your brand and social media strategy and we will create the content that strategically communicates your brand values, personalities, mission and goals."
  },
  {
    header_img: pisellLogo,
    imgClassName: "w-145px min-h-105px flex items-center",
    heading1: "Software Services",
    heading2: "Pisel",
    link: "https://www.pisell.com/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "Pisell is a SaaS company dedicated to helping businesses find their customers, expand their online presence and improve efficiency in an all-round way. Founded in Australia, we are headquartered in Melbourne with offices all around Australia."
  },
  // {
  //   header_img: ziiLogo,
  //   imgClassName: "w-133px flex items-center min-h-105px",
  //   heading1: "POS",
  //   heading2: "Zii",
  //   link: "https://www.ziicloud.com/",
  //   checkboxImg: checkboxImg,
  //   checkText1: "LUCA Pay",
  //   checkText2: "E-invoicing enabled",
  //   body: "We are a team of technologists, business builders and entrepreneurs who have come together to transform high street commerce into a connected and thriving digital ecosystem that creates value for all participants."
  // } ,
  {
    header_img: cfoLogo,
    imgClassName: "w-160px",
    heading1: "Accounting & Business Consulting",
    heading2: "Your CFO Partner",
    link: "https://yourcfopartner.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "With 15 years experience working with construction business, Victor set up the business back in 2017. Since then, he is on a mission to help every construction business he touch to maximize profit margin and optimize cash flow."
  },
  {
    header_img: targetAccoutingLogo,
    imgClassName: "w-133px",
    heading1: "Accounting & Business Consulting",
    heading2: "Target Accounting",
    link: "https://targetaccounting.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "As a CPA and Xero certified advisor I’ve served at all levels of the business world in the last 10 years. There is nothing more fulfilling than empowering business owners to take control of their financial situation."
  },
  {
    header_img: ozdLogo,
    imgClassName: "w-145px",
    heading1: "Accounting & Business Consulting",
    heading2: "OZD Accounting & Business Advisory",
    link: "http://www.ozdadvisory.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "We facilitate and make accounting and finance easy.  We believe in building and nurturing trusting Relationships. We do this by providing, Honest advice, Independence, Accountability, Leadership."
  },
  {
    header_img: anconsult,
    imgClassName: "w-145px",
    heading1: "Accounting & Business Consulting",
    heading2: "Anconsult Business Solutions",
    link: "https://anconsult.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "In 2012, Anja Carruthers opened the first office of Anconsult Business Solutions on the Central Coast of New South Wales. Back then, we operated as a BAS agent specialising in small businesses."
  },
  {
    header_img: cocalex,
    imgClassName: "w-12 min-h-105px flex items-center",
    heading1: "Financial Consulting, Business Lending & Digital Content Creation",
    heading2: "Cocalex",
    link: "https://www.cocalex.consulting/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "John has been a senior level executive within the lending and mortgage broking industry, having fulfilled a holistic repertoire of roles and projects in his 20 plus year career."
  },
  {
    header_img: financeMadeSimpleLogo,
    imgClassName: "w-24 flex items-center",
    heading1: "Financial Consulting, Business Lending & Digital Content Creation",
    heading2: "Finance Made Easy",
    link: "http://hofinancemadesimple.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "JBorrowing money for ventures such as buying your first home, purchasing an investment property or building a new home can be more complex than many people imagine. With a huge range of lenders and products on the market ..."
  }
]



function Banner() {

  return (
    <div className="marketing-page">
      <div className="banner">
        <div className="banner__top">
          <div className="container">
            <div className="banner__top__inner flex flex-col">
              <h3 className="text-style">
              Business made easy with caring suppliers
              </h3>
              <h5 className="sub-text-style">
              Find and connect with trusted suppliers to make business payments and accounting transactions easier, simpler and smarter
              </h5>
            </div>
          </div>
        </div>
    </div>
      <div className="carousel categories">
        <CarouselProvider
          visibleSlides={3}
          totalSlides={market_places.length}
          step={3}
          naturalSlideWidth={850}
          naturalSlideHeight={800}
        >
          <div className={"container"}>
            <Slider>
                {market_places.map((place, index) => (
                  <Slide index={index}>
                    <div className="justify-content-center sm:grid box relative gap-3 flex-col" >
                      <div className={`header m-auto cursor-pointer ${place.imgClassName}`}>
                        <img src={place.header_img} className={`img1`} onClick={() => window.open(place.link)} />
                      </div>
                      <hr className="hr" />
                      <div className="text-neutral-3 text-15">
                      <div className="color-grey"> {place.heading1} </div>
                      <div className="font-weight-5"> {place.heading2} </div>
                      {/*<div className="flex text-item">*/}
                      {/*<img src={place.checkboxImg} className="pr-5" /> <span className="pr-2"> {place.checkText1} </span>*/}
                      {/*<img src={place.checkboxImg} className="pr-5" /><span className="pr-2"> {place.checkText2} </span>*/}
                      {/*</div>*/}
                      <div className="pt-3 flex flex-col">
                        <div className={`text font-normal`}>
                          {place.body.length > 220 ? `${place.body.slice(0, 220)} ...` : place.body}
                          {/*<span className='pl-1'><a className={`text-mind read-more cursor-pointer hover:underline`} onClick={() => readmore(place)}>*/}
                          <span className='pl-1'>
                      <a className={`text-mind read-more cursor-pointer hover:underline`} onClick={() => window.open(place.link)}>
                       read more
                      </a>
                    </span>
                        </div>

                      </div>
                    </div>
                    </div>
                  </Slide>
                ))}
            </Slider>
            <ButtonBack className={"buttonBack"}>{`<`}</ButtonBack>
            <ButtonNext className={"buttonNext"}>{`>`}</ButtonNext>
          </div>
          <DotGroup className={"dotGroup"} />
        </CarouselProvider>
      </div>
    </div>
  )
}
export default Banner;
