
import icon1 from "../../images/partner/icon1.svg"
import icon2 from "../../images/partner/icon2.png"
import icon3 from "../../images/partner/icon3.svg"
import icon4 from "../../images/partner/icon4.svg"
import icon5 from "../../images/partner/icon5.svg"
import icon6 from "../../images/partner/icon6.svg"


const block_data = [
  {
    icon: icon3,
    title: "Satisfaction",
    desc: "A premium experience to ease cash flow for users, dedicated support for onboarding and queries",
    link: "/signup/",
    but: "Get Started",
    btnClassName: "sm:pt-5 pt-0"
  },
  {
    icon: icon6,
    title: "Scaling",
    desc: "Great business exposure in our market place, attractive and scalable referral rewarding program",
    link: "/ebook/",
    but: "Download Now",
    btnClassName: "sm:pt-5 pt-0"
  },
  {
    icon: icon5,
    title: "Success",
    desc: "Generating more revenue, acquiring more users and growing our businesses together",
    link: "/signup/",
    but: "Find Out",
    btnClassName: "sm:pt-5 pt-0"
  },
]


export default block_data
