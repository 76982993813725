import React from "react"
import trustImage from "../../images/partners/trust-partner-img.svg"

export default function trustCompany() {
  return (
    <div className="trusts">
      <div className="container">
        <div className="header">
          Get Listed on our Partner Marketplace to generate more business
        </div>
        {/*<div className="main-img sm:block hidden">*/}
        {/*  <img src={trustImage} />*/}
        {/*</div>*/}
        {/*<div className="main-img-mob sm:hidden">*/}
        {/*  <div className="mover-1"/>*/}
        {/*  <div className="mover-2"/>*/}
        {/*</div>*/}
      </div>
    </div>
)
}
